import { ILanguageKeys } from './interface'

export const enUs: ILanguageKeys = {
  threeMonths: '3 Months',
  sixMonths: '6 Months',
  tenMonths: '10 Months',
  oneYear: '1 Year',
  aVehicleWasNotFound:
    'A vehicle was not found. Please select vehicle manually.',
  accessoriesSuppliesAndTools: 'Accessories, Supplies and Tools',
  account: 'Account',
  add: 'Add',
  ADD: 'Add',
  all: 'All',
  actions: 'Actions',
  addA: 'Add a',
  addACustomName: 'Add a custom Name',
  addAllToCart: 'Add all to cart',
  addAtleastOneItemToList: 'Add atleast one item to list',
  addItems: 'Add Items',

  addItemsToGroup: 'Add Items to Group',
  addJust: 'Add just',
  addManually: 'ADD MANUALLY',
  addNewList: 'Add New List',
  addPartToList: 'Add part to lists',
  addParts: 'Add Parts',
  addQuoteToCart: 'Add Quote to Cart',
  allLines: 'All Lines',
  updateAndAddToCart: 'Update And Add To Cart',
  addToCart: 'Add to Cart',
  addToList: 'Add to List',
  addVehicle: 'Add Vehicle',
  additionalDetails: 'Additional Details',
  additionalOptions: 'Additional Options',
  additionalResources: 'Additional Resources',
  adhesives: 'Adhesives',
  advancedSearch: 'Advanced Search',
  aftermarketPartAvailable: 'Aftermarket Part Available',
  aftermarketAutoPartsAlliance: 'Aftermarket Auto Parts Alliance',
  all31Days: 'All (31 days)',
  allCarts: 'all carts',
  allItemsFromEachCartWillBeRemovedAndCannotBeUndone:
    'All items from each cart will be removed and cannot be undone.',
  allItemsFromThisOrderHaveBeenReturned:
    'All items from this order have been returned',
  allianceConnect: 'Alliance Connect',
  allOrders: 'All Orders',
  allParts: 'All Parts',
  allProducts: 'All Products',
  allRightsReserved: 'All Rights Reserved',
  allVehicles: 'All Vehicles',
  allVehiclesWereSavedAsQuotes: 'All vehicles were saved as quotes',
  alternateFor: 'Alternate for',
  available: 'Available',
  amount: 'amount',
  amountRequestedCanBeOrderedButWillTakeLongerToBeDelivered:
    'Amount requested can be ordered but will take longer to be delivered',
  amountRequestedUnavailableItCanBeOrderedFromOtherLocation:
    'amount requested unavailable, It can be ordered from other location',
  anAdminWillBeReachingOutToYou:
    'An admin will be reaching out to you. If you do not hear back, please contact customer support.',
  and: 'and',
  applySort: 'Apply sort',
  applyChanges: 'Apply Changes',
  apply: 'Apply',
  areNotAvailableInOurDatabase: 'are not available in our database.',
  areYouSureYouWantTo: 'Are you sure you want to',
  areYouSureYouWantToCancelReturnRquest:
    'Are you sure you want to cancel this return request?',
  areYouSureYouWantToDelete: 'Are you sure you want to delete',
  areYouSureYouWantToEmptyYour: 'Are you sure you want to empty your',
  areYouSureYouWantToEmptyYourCart: 'Are you sure you want to empty your cart?',
  areYouSureYouWantToRemove: 'Are you sure you want to remove',
  areYouSureYouWantToRemoveYour: 'Are you sure you want to remove your',
  areYouSureYouWantToRemoveTheReturnRequest:
    'Are you sure you want to remove the % from this return request?',
  areYouSureYouWantToUpdate: 'Are you sure you want to update',
  arrivedLate: 'Arrived Late',
  arrivingOn: 'Arriving on %',
  arrivedOn: 'Arrived on %',
  ast: 'AST',
  at: 'at',
  automaticEntry: 'automatic entry.',
  automaticEntryYouHave: 'automatic entry. You have ',
  availability: 'Availability',
  awaitingShipment: 'Awaiting Shipment',
  back: 'Back',
  backToHome: 'Back to home',
  backToAllDiagrams: 'Back to all diagrams',
  backToCart: 'Back to Cart',
  backToLogin: 'Back to login',
  backToOrders: 'Back to Order History',
  backToOrderDetails: 'Back to Order Details',
  backToInvoice: 'Back to Invoice Details',
  backToPartType: 'Back to Part Type',
  backToPreviousPage: 'Back to Previous Page',
  backToReturnSelection: 'Back to Return Selection',
  backToReviewItems: 'Back To Review Items',
  backToSavedQuotes: 'Back to Saved Quotes',
  backToTop: 'Back to top',
  belowIsAListOfAllOE:
    'Below is a list of all OE and aftermarket parts, including items not sold in our warehouses. This tool is for reference only.',
  basicDetails: 'Basic Details',
  bestEmailToReachYouAt: 'Best email to reach you at',
  brand: 'Brand',
  brandAZ: 'Brand (A-Z)',
  brandZA: 'Brand (Z-A)',
  Brands: 'Brands',
  browseFile: 'Browse File',
  buyAgain: 'Buy Again',
  buyer: 'Buyer',
  callForAvailability: 'Call for availability',
  callForPrice: 'Call for Price',
  cancel: 'Cancel',
  cancelRequest: 'Cancel request',
  cancelReturnRequest: 'Cancel Return Request',
  cannotDeleteCategory: 'Can not delete category: %',
  cart: 'Cart',
  cartOnlyHasLaborInIt: '% cart only has labor in it',
  cartTotal: 'Cart Total',
  cartsOnlyHaveLaborInThem: '% carts only have labor in them',
  cat: 'Cat',
  catalogDiagram: 'Catalog Diagram',
  categories: 'Categories',
  category: 'Category',
  categoryDeletionError: 'Category deletion error',
  categoryNameAlreadyExists: 'Category name already exists',
  categoryNameExceedsChars: 'Category name exceeds 30 characters',
  change: 'change',
  changeGraphic: 'Change Graphic',
  changeLoaction: 'Change Loaction',
  changeLocation: 'Change Location',
  changeVehicle: 'Change Vehicle',
  changedStoreDueToAvailability: 'Changed store due to item availability',
  chemicals: 'Chemicals',
  choosePartType: 'Choose Part Type',
  choosePartTypeDescription:
    'Optimize my interchange search by first displaying all part types that correspond to the specified part number before showing my results.',
  chooseVehicleSystem: 'Choose Vehicle System',
  clear: 'clear',
  clearAll: 'Clear all',
  clearAllFilters: 'Clear All Filters',
  clearFilters: 'Clear Filters',
  clearSearch: 'Clear Search',
  clearSelections: 'Clear Selections',
  click: 'Click',
  clickButtonBelowToBeginAddingItemsToThisGroup:
    'Click button below to begin adding items to this group',
  clickPartToSelectAndHighlight:
    'Click part to select it and highlight it in list below',
  clickToViewAvailableMfgList: 'Click to view available MFG list',
  clickToViewPrintPartNumbers: 'Click to view/print part numbers',
  clickingThisPartWillChangeGraphic:
    'Clicking this part will change the graphic that is currently displayed',
  close: 'Close',
  closeGroups: 'Close Groups',
  code: 'Code',
  commentOptional: 'Comment (Optional)',
  comment: 'Comment',
  comments: 'Comments',
  costChangedFrom: 'Cost changed from % to %',
  confirmNewPassword: 'Confirm New Password',
  confirmation: 'Confirmation #',
  confirmationID: 'Confirmation ID',
  confirmationId: 'Confirmation Id',
  confirmationNumber: 'Confirmation Number',
  congratulationsThisOrderHasQualifiedFor:
    'Congratulations! This order has qualified for ',
  congratulationsThisOrderQualifiesFor:
    'Congratulations! This order qualifies for',
  contactAdminToRequestPasswordReset:
    'Contact admin to request a password reset',
  contactUsFormErrorMessage: 'An error occurred. Please try again later',
  contactUs: 'Contact Us',
  continue: 'Continue',
  continueRquest: 'Continue Rquest',
  controlID: 'control ID',
  copiedToClipboard: 'Copied to Clipboard',
  copypart: 'Copy Part',
  core: 'Core',
  coreCost: 'Core Cost',
  coreFee: 'Core Fee',
  coreList: 'Core List',
  coreOnly: 'Core Only',
  corePrice: 'Core Price',
  cost: 'Cost',
  condensed: 'Condensed',
  costBreakdown: 'Cost Breakdown',
  costDetails: 'Cost Details',
  costHighLow: 'Cost (High-Low)',
  costLowHigh: 'Cost (Low-High)',
  costSummary: 'Cost Summary',
  createCategory: 'Create Category',
  createGroup: 'Create Group',
  createList: 'Create List',
  createdDate: 'Created Date',
  createdOn: 'Created on',
  crossReferenceToolNoResults: 'Cross Reference Tool',
  crossReference: 'Cross Reference',
  crossReferenceTool: 'cross-reference tool',
  crossReferenceTooltipMsg:
    'Use our Cross Reference Tool for a complete list of all OE and aftermarket parts interchangeable with %',
  crossReferenceResults: 'Cross Reference Results',
  current: 'Current',
  customize: 'Customize',
  customMakes: 'Custom Makes',
  customizeMakeList: 'Customize Make List',
  selectTheMakesThatYouWantToSeeWhenAddingAVehicle:
    'Select the makes that you want to see when adding a vehicle',
  customName: 'Custom Name',
  customerName: 'Customer name',
  customizeSearch: 'Customize search',
  customizeSearchPreferences: 'Customize Search Preferences',
  clearVehicle: 'Clear Vehicle',
  damagedPartInbox: 'Damaged Part Inbox',
  dashboard: 'Dashboard',
  dateRange: 'Date Range',
  dateRequested: 'Date Requested',
  default: 'default',
  defaultVehicleType: 'Default vehicle type',
  defaultSetting: 'Default',
  defective: 'Defective',
  delete: 'Delete',
  deleteCategory: 'Delete category',
  deleteGroup: 'Delete Group',
  deleteItemFromList: 'Delete items from lists',
  deleteItems: 'Delete items',
  deleteList: 'Delete List',
  deleteMessage: 'Are you sure you want to delete',
  deleteQuote: 'Delete Quote',
  deleteQuotes: 'Delete Quotes',
  deleteQuotesOlderThan: 'Delete quotes older than',
  deleteVehicle: 'Delete vehicle',
  delivered: 'delivered',
  deliveryStatus: 'Delivery Status',
  deliveryStatusUnavailable: 'Delivery Status Unavailable',
  deliveryStatusUnavailableOrderPlacedByOtherMeans:
    'Delivery status unavailable. This order was placed via phone, walk-in, or through another application.',
  deliveryTime: 'Delivery Time',
  description: 'Description',
  descriptionAZ: 'Description (A-Z)',
  descriptionZA: 'Description (Z-A)',
  descriptions: 'Descriptions',
  deselectAll: 'Deselect All',
  desktop: 'Desktop',
  details: 'Details',
  diagram: 'Diagram',
  diagrams: 'Diagrams',
  didYouMeanToSearchFor: 'Did you mean to search for',
  directOrder: 'Direct Order',
  discardAndStartNew: 'Discard and start a new',
  discardAndStartNewCartMessage:
    'You have cart items for the current vehicle. Would you like to discard these items and start a new order?',
  dismiss: 'Dismiss',
  dispatchAndDelivery: 'Dispatch & Delivery',
  dispatchNotificationNotFound: 'Dispatch Notification Not Found',
  documentID: 'Document ID',
  dontAskPartTypeAgain: `Don't ask "Part Type" again`,
  dontAskManufacturerAgain: `Don't ask "From Manufacturer" again`,
  doingSoWillRemoveAllItems: 'Doing so will remove all items.',
  dragAndDrop: 'or drag and drop it here',
  dragAndRearrangeTheItemsInThisListToArrangeThemInYourPreferredDisplayOrder:
    'Drag and drop the items in this list to arrange them in your preferred display order',
  duplicateVehicle: 'Duplicate Vehicle',
  // eachAndEveryPerfectStopProductPurchaseearnsanenrty:
  //   'Every PerfectStop branded product earns you an automatic entry and a chance to WIN!',
  eachAndEveryPerfectStopProductPurchaseearnsanenrty:
    'Each qualifying invoice total earns you an entry, daily bonus entries added for every $150 increment',
  edit: 'Edit',
  editCustomName: 'Edit Custom Name',
  editFitmentConditions: 'Edit Fitment Conditions',
  editItem: 'Edit Item',
  editList: 'Edit List',
  editParts: 'Edit Parts',
  editSearch: 'Edit Search',
  editUnknownItem: 'Edit Unknown Item',
  editVehicle: 'Edit Vehicle',
  email: 'Email',
  emailAddress: 'Email Address',
  emailRequired: 'Email Required',
  emptyCart: 'Empty Cart',
  engine: 'Engine',
  english: 'English',
  enterAPlateNumber: 'Enter a plate number',
  enterCode: 'Enter Code',
  enterNewPassword: 'Enter New Password',
  enterPart: 'Enter Part # to Interchange or Search',
  enterVehicle: 'Enter Vehicle',
  enterVin: 'Enter VIN',
  entryAddjust: 'entry(s). Add just ',
  entryToDate: 'entry(s) to date.',
  entryYouHaveReachedTheMaximumNumberOfEntriesYouHave:
    'entry(s). You have reached the maximum number of entries. You have ',
  errorAddingProductsToTheCart: 'Error adding products to the cart',
  errorDeletingVehicle: 'Error deleting vehicle',
  errorInReloadingVehicleFitments: 'Error in reloading vehicle fitments',
  errorLoadingBannerPromotion: 'Error loading banner promotion',
  errorLoadingEntries: 'Error loading Entries',
  errorMovingList: 'Error moving list',
  errorOccurredWhileUpdatingVehicleName:
    'An error occurred while updating vehicle name.',
  errorProcessingOrders: 'Error processing orders',
  errorUpdatingProductQuantity: 'Error updating product quantity',
  errorUpdatingLaborRate: 'Error updating labor rate',
  errorValidatingList: 'An error occurred while validating the list.',
  estimatedDelivery: 'Estimated Delivery',
  estimatedReturn: 'Estimated Return',
  exactMatch: 'Exact Match',
  existingCartWarningModal:
    'You have an existing vehicle in your cart that matches these specifications. Do you want to start a new cart or merge with the existing % cart?',
  expandAll: 'Expand All',
  experianAutocheck: 'Experian Autocheck',
  explanation: 'Explanation',
  exportList: 'Export list',
  extraDetails: 'Extra Details',
  favorites: 'Favorites',
  failedToLogin: 'Failed to login',
  failedToAcceptTermsAndConditions: 'Failed to accept Terms and conditions',
  feelFreeToReachTextHD:
    ' If you feel you have reached this message in error or have any questions, please contact us.',
  fieldsForShopUse: 'Fields for Shop Use',
  filter: 'Filter',
  filterBy: 'Filter By',
  filterByDateRange: 'Filter By Date Range',
  filterResults: 'Filter Results',
  filterSelectLines: 'Filter : Select Lines',
  filterSort: 'Filter & Sort',
  fitmentConditions: 'Fitment Conditions',
  fitsYears: 'Fits Years',
  forFullSweepstakesDetails: 'for full Sweepstakes details',
  forShopUse: 'For shop use only',
  forgotPassword: 'Forgot Password%',
  formIcomplete: 'Form Incomplete',
  french: 'French',
  general: 'General',
  getLaborData: 'Get labor data',
  getThePerfectResults: 'Get the perfect results',
  gfx: 'GFX',
  gfxCatalog: 'GFX Catalog',
  gfxSpecificAndAttributeConditions: 'GFX Specific and Attribute Conditions',
  gfxUnavailableForThisVehicle: 'GFX unavailable for this vehicle',
  go: 'Go',
  goToAccessoriesSuppliesAndTools:
    'Go to accessories, supplies and tools (AST)',
  goToASTCatalog: 'Go to AST Catalog',
  goToCart: 'Go To Cart',
  goToFirstPage: 'Go To First Page',
  goToHome: 'Go to Home',
  goToNextPage: 'Go To Next Page',
  goToPreviousPage: 'Go To Previous Page',
  goToProductPage: 'Go To Product Page',
  grandTotal: 'Grand Total',
  graphicId: 'Graphic ID',
  group: 'Group',
  groupName: 'Group Name',
  groupNameAlreadyExists: 'Group name already exists',
  groups: 'Groups',
  hashTagParts: '# Parts',
  here: 'here',
  hidden: 'hidden',
  hideMore: 'Hide More',
  hold: 'Hold',
  home: 'Home',
  homePage: 'Home Page',
  hours: 'Hours',
  howToReadThisGraphic: 'How to Read This Graphic',
  howToViewSearchResults: 'How would you like to view your search results?',
  id: 'ID',
  ifYouDoNotReceiveEmailWithCode:
    'If you do not receive an email with the code please contact your parts supplier.',
  ifYouNeedThisGraphic:
    'if you need this graphic and we will work on your request as soon as possible.',
  import: 'Import',
  importComplete: 'Import Complete',
  importFile: 'IMPORT FILE',
  importList: 'Import List',
  importListDescription:
    'Import multiple items to a list from a CSV file. It should include columns for Group, MFR, Part Number, Stocking Qty and Comments.',
  importListTemplateCSV: 'Use our template list (CSV)',
  importantMessageAboutItemsInYourCart:
    'Important message about items in your cart',
  itemWasnotAddedToYourCart: '1 item was not added to your Cart',
  itemsWereNotAddedToYourCart: '% items were not added to your Cart',
  itemFromYourCartIsUnavailable: '1 item from your cart is unavailable',
  itemsFromYourCartAreUnavailable: '% items from your cart are unavailable',
  inCart: 'In Cart',
  increasedFromTo: '% went from % to %',
  interchange: 'Interchange',
  interchangeSearch: 'Interchange Search: %',
  interchangeSearchTextError:
    'Interchange text length must be between 4 and 14 characters',
  interchangesearch: 'Interchange Search',
  interchangeDisclaimer:
    'Interchange results are for reference only. Application should be verified.',
  interchangeInfoMessage:
    'If you don’t see the item you are looking for, try using our % to see a complete list of all OE and aftermarket parts interchangeable with %',
  interchangeFor: 'Interchange for',
  invalidEmailAddress: 'Invalid email address',
  invalidCredentials: 'Invalid credentials',
  invalidVin: 'Invalid VIN',
  invoice: 'Invoice',
  invoiceDetails: 'Invoice Details',
  invoiceDate: 'Invoice Date',
  invoiceNumber: 'Invoice Number',
  invoicedOn: 'Invoiced On',
  inputPartTypeThenHitEnter: `Input Part Type then hit 'Enter' / 'Return' key to search`,
  isNotAvailableInDatabase: 'is not available in our database.',
  issueInAddingPartsToCart:
    'There was an issue in adding your items to the cart. Please check the cart page for details',
  item: 'Item',
  itemInYourCartCantBeFulfilled: "1 item in your cart can't be fulfilled",

  itemInYourCartHasChangedPrice: '1 item in your cart has changed price',
  itemInYourCartHaveInsufficientQuantity:
    '1 item in your cart has insufficient quantity',
  itemInYourCartWillBeOrderedFromNonPrimaryLocation:
    '1 item in your cart will be ordered from a non-primary location',
  itemUnavailable: 'Item unavailable in our database.',
  itemUnavailableAtAllLocations: 'Item unavailable at all locations.',
  itemWasNotAdded: 'items not added to your cart',
  itemWasNotTransferred: '1 item was not transferred',
  items: 'Items',
  itemsAddedToTheCart: 'items added to the cart',
  itemsAvailability: '% of 8 available',
  itemsInYourCartCantBeFulfilled: "% items in your cart can't be fulfilled",
  itemsInYourCartHaveChangedPrice: '% items in your cart have changed price',
  itemsInYourCartHaveInsufficientQuantity:
    '% items in your cart have insufficient quantity',
  itemsInYourCartWillBeOrderedFromNonPrimaryLocation:
    '% items in your cart will be ordered from a non-primary location',
  itemsPerPage: 'items per page',
  itemsReflectTheCurrentPrices:
    'Items reflect the current prices at the location selected',
  itemsRequireYourAttention: 'items require your attention',
  itemsRestock: 'Items that are restocked every four months',
  itemsSelected: 'items selected across 1 list',
  itemsWereNotTransferred: '% items were not transferred',
  itemvalidated: 'Item Validated',
  jobs: 'Jobs',
  justAMoment: 'Just A Moment.',
  labor: 'Labor',
  laborCost: 'Labor cost',
  laborData: 'Labor Data',
  laborId: 'Labor ID',
  laborLookup: 'Labor Lookup',
  laborOperationsDescriptions: 'Labor Operations Description',
  laborRate: 'Labor Rate',
  laborRateUpdatedSuccessfully: 'Labor Rate updated successfully',
  laborRatePerHour: 'Labor rate per hour',
  tackNo: 'Tack No',
  tapAndRollOverImageToMagnify: 'Tap and roll over image to magnify',
  toHideAnItemClickTheEyeIcon: 'To hide an item, click the eye icon.',
  TotalCostInHours: 'Total cost',
  totalCount: 'Total Count',
  laborsUpdated: 'Labors Updated',
  last12Months: 'Last 12 Months',
  last30Days: 'Last 30 Days',
  last7Days: 'Last 7 Days',
  lastUsed: 'Last Used',
  lastUsedAZ: 'Last Used (Ascending)',
  lastUsedZA: 'Last Used (Descending)',
  license: 'License',
  licensePlate: 'License Plate',
  Lines: 'Lines',
  lineCode: 'Line Code',
  list: 'List',
  listDeletionError: 'List deletion error',
  listHighLow: 'List (High-Low)',
  listImported: 'List imported successfully',
  listImportedError: '% lines contained errors and could not be imported',
  listImportedSuccessfully: 'Your list was imported successfully (% lines)',
  listImportTimeOutMsg:
    'The server did not respond on time. Please try again in a few moments.',
  listLowHigh: 'List (Low-High)',
  listName: 'List Name',
  listNameAlreadyExists: 'List name already exists',
  listPrice: 'List Price',
  listPriceBreakdown: 'List Price Breakdown',
  lists: 'Lists',
  loadingXResults: 'Loading % results',
  location: 'Location',
  locationChangedTo: 'Location changed to: %',
  locationType: 'Location Type',
  locationSecNo: 'Location Sec No',
  lookupDate: 'Lookup Date',
  lookupItems: 'Lookup Items',
  lookupSource: 'Lookup Source',
  lookupType: 'Lookup Type',
  lostSaleSuccess: 'Lost Sale Report Sent',
  logOut: 'Logout',
  make: 'Make',
  makeThisMyDefaultState: 'Make this my default state',
  manufacturer: 'Manufacturer',
  manufacturers: 'Manufacturers',
  manufacturerDetails: 'Manufacturer Details',
  manufacturerInfo: `If you know the manufacturer of the part you are interchanging from, select it below. If not, click "Select All".`,
  markAsFavorite: 'Mark as Favorite',
  markedAsFavorite: 'Marked as Favorite',
  mayNotFitYour: 'May not fit selected vehicle',
  mergeWithExistingCart: 'Merge with existing cart',
  message: 'Message',
  messageSent: 'Message Sent!',
  messageSentSuccess:
    'Thank you for your message someone will get back to you based on your priority.',
  method: 'Method',
  mfr: 'Mfr',
  mfrAZ: 'MFR (A-Z)',
  mfrZA: 'MFR (Z-A)',
  mfrValidationMessage:
    'Line code should not be less than % and more than % characters.',
  mileage: 'Mileage',
  minQuantity: 'Min Quantity',
  miscellaneous: 'Miscellaneous',
  missingPartInbox: 'Missing Part Inbox',
  model: 'Model',
  more: 'more',
  moreDetails: 'More Details',
  moreOptions: 'More Options',
  moreActions: 'More Actions',
  move: 'Move',
  moveGroup: 'Move Group',
  moveList: 'Move list',
  moveToAnotherList: 'Move to another list',
  mustIncludeAPartToOrderThisCart: 'must include a part to order this cart',
  multiPartInquiry: 'Multipart Inquiry',
  multiPartInquiryValidationError:
    'Invalid lineCode, Special characters are not allowed/length of linecode should be min 2 chars and maximum 3 chars',
  myStore: 'My Store',
  myPlaceForParts: 'MyPlace4Parts',
  name: 'Name',
  needHelpResettingYourPassword: 'Need help resetting your password?',
  new: 'New',
  newCategory: 'New category',
  newGroup: 'New Group',
  newList: 'New List',
  newLists: 'New Lists',
  newParts: 'New Parts',
  newPassword: 'New Password',
  newReturn: 'New Return',
  next: 'Next',
  nextChooseList: 'Next: Choose List',
  nextSelectGroup: 'NEXT: select group',
  nextSelectDescription: 'NEXT: select description',
  nextSelectPartTypes: 'NEXT: select part types',
  noAdditionalDetails: 'No Additional Details',
  noData: 'No Data Available',
  noImage: 'No Image Available',
  noMoreResults: 'No More Results',
  noOrdersAvailable: 'No Orders Available',
  noInvoicesAvailable: 'No Invoices Available',
  noPartsFoundForThisGroup: 'No parts found in this group',
  noPartsFoundForThisList: 'No parts found in this list',
  noPartsFoundAddedToCart: 'No parts were added to the cart',
  noQuotesAvailable: 'No Quotes Available',
  noResults: 'No Results',
  noResultsFound: 'No results found',
  noResultsFoundForHD: `NO RESULTS FOUND FOR "%"`,
  noListResultsFound: 'No Lists Found',
  noResultsFoundForThisVehicle: 'No results found for this vehicle',
  noServiceInterval: 'Service Interval not available',
  noVehicleHistoryAvailable: 'No vehicle history available',
  normal: 'Normal',
  notAddedDueToInsufficientQuantity: 'Not added due to insufficient quantity',
  note: 'Note',
  notes: 'Notes',
  noteToStore: 'Note to Store',
  notesToStore: 'Notes to Store',
  notPrimaryLocation: 'Not Primary Location',
  oePart: 'OE Part',
  oeParts: 'OE Parts',
  oePartNumbersDisclaimer:
    'OE part numbers are for reference only. Application should be verified.',
  okIUnderstand: 'Ok, I understand',
  onThisOrderFor: 'on this order for',
  onThisOrderForAnAutomaticEntry: 'on this order for an automatic entry.',
  onlineCatalog: 'Online Catalog',
  onlyLaborResultsAreAddedToCart: 'Only Labor Results are added to cart',
  onlyOutOfRequestedAreAvailable: 'Only % out of % requested are available',

  onlySpecsAreAddedToCart: 'Only specifications are added to cart',
  onlySpecsLaborResultsAreAddedToCart:
    'Only Specifications and Labor Results are added to cart',
  'Only Priced': 'Only Priced',
  'Only Stocked': 'Only Stocked',
  openGroups: 'Open Groups',
  openImageInNewTab: 'Open image in new tab',
  or: 'OR',
  order: 'Order',
  orderAllCarts: 'Order all carts',
  orderConfirmation: 'Order Confirmation',
  orderConfirmationError:
    'Please review the error message above and resubmit your order from the Cart page.if you continue to have issues, please contact us.',
  orderAllCartsConfirmation: 'Are you sure you wish to Order All Carts?',
  orderDate: 'Order Date',
  orderDetails: 'Order Details',
  orderFailed: 'Order Failed',
  orderForm: 'Order Form',
  orderFormIsIncomplete: 'Order form is incomplete.',
  orderHistory: 'Order History',
  orderNotFound: 'Order not found',
  orderNumber: 'Order Number',
  orderSummary: 'Order Summary',
  orderThisCart: 'ORDER THIS CART',
  orderTotal: 'Order Total',
  orderFromPrimaryWarining:
    'Orders to this location will be processed by your local store',
  OrdersWillBeProcessedByYourLocalStore:
    'Orders will be processed by your local store',
  orderType: 'Order Type',
  orderConfirmationNumber: 'Order Confirmation Id',
  ordered: 'Ordered',
  orderedBy: 'Ordered By',
  orderedOn: 'Ordered On %',
  orders: 'Orders',
  orderError: 'Error: Order Failed. ',
  orderSequence: '0rder Sequence',
  orgID: 'org ID',
  origin: 'Origin',
  other: 'Other (Please Specify)',
  others: 'Other',
  outOf: 'out of',
  outOfStock: 'Out of stock',
  outOfStockAtAllLocationsWillBeOrderedFromOtherStore:
    'out of stock at all locations, will be ordered from %',
  outOfStockAtUpdatedTo: 'out of stock at %, updated to %',
  packed: 'Packed',
  page: 'page',
  part: 'Part',
  partVehicleFitment: 'Part',
  parts: 'Parts',
  partAvailableInAnotherGroup: 'Part Available in Another Group',
  partAvailableInCurrentScreen: 'Part Available in Current Screen',
  partDescription: 'Part Description',
  partDescriptionAZ: 'Part Description (A-Z)',
  partDescriptionZA: 'Part Description (Z-A)',
  partInquiry: 'Part Inquiry',
  partIsAlreadyInCartFor: 'Part is already in cart for',
  partIsValidText: `% is a valid part number, but we do not carry this part or any interchangeable products.`,
  partIsValidSubText: `You may use our cross reference tool to see a complete list of all OE and aftermarket parts interchangeable with % or try another search`,
  partIsNotVaildText: `We couldn’t find a valid part number matching %`,
  partIsNotVaildSubText:
    'Please double-check the part number for accuracy or try another search',
  partNum: 'Part #',
  'Part#': 'Part #',
  partNumber: 'Part Number',
  partNumberAZ: 'Part Number (A-Z)',
  partNumberIsEmpty: 'Part Number is Empty',
  partNumberZA: 'Part Number (Z-A)',
  partSelected: 'Part Selected',
  PartType: 'Part Type',
  partType: 'Part Type',
  partTypes: 'Part Types',
  partWasAddedToYourCart: '%  was added to your cart',
  partWasRemovedFromYourCart: '% was removed from your cart',
  partsHaveBeenAddedToYourCart: 'Parts have been added to your cart',
  partsCatalog: 'Parts Catalog',
  password: 'Password',
  passwordReset: 'Password Reset',
  passwordsMustMatch: 'Passwords must match',
  pending: 'PENDING',
  perCarQty: 'Per Car Qty',
  personalNote: 'Personal note',
  phoneNumber: 'Phone Number',
  phoneNumberRequired: 'Phone number required',
  plateNumber: 'Plate Number',
  pleaseAddAVehicleToEnsureFitment: 'Please add a vehicle to ensure fitment',
  pleaseContactForInformation: 'Please contact % for information',
  pleaseEnterTheCodeThatWasSentTo: 'Please enter the code that was sent to %',
  pleaseEnterYourContactDetailsAndAnAdminWillBeInContactWithYou:
    'Please enter your screen name, email address, and phone number, and an admin will be in contact with you',
  pleaseEnterYourScreenNameToSendVerificationCode:
    'Please enter your screen name to send a verification code to your email',
  pleaseUploadValidCsvFile: 'Please upload valid CSV file',
  pleaseUploadCsvFile: 'Please upload csv file',
  po: 'PO',
  poID: 'P0 ID',
  poNumber: 'PO Number',
  previousSearches: 'Previous Searches',
  price: 'Price',
  pricePerItem: 'Price Per Item',
  primary: 'Primary',
  print: 'Print',
  printCatalogDiagram: 'Print Catalog Diagram',
  printList: 'Print List',
  priority: 'Priority',
  ProcessedByLocalStore: 'Processed by local store',
  goToProductPageToSelectAnotherLocation:
    'go to product page to select another location',
  productCantBeFulfilled: 'Product Cant be fulfilled',
  productCantBeFulfilledFromSelectedLocation:
    'Products cant be fulfilled from the selected location',
  productToThisOrderForAnAutomaticEntry:
    'product to this order for an automatic entry.',
  products: 'Products',
  productsWereAddedToTheCart: 'Products were added to the cart',
  promoPolicy:
    'for full Sweepstakes details. *Estimate. Actual MyPlace4Parts entry counts updated within 24-48 hours',
  purchaseOrderNumber: 'Purchase Order Number',
  purolatorCutoffTimeMessage: 'If ordered by % - Monday through Friday',
  purolatorDisclaimerMessage1:
    'Please note that freight costs are approximate and may vary depending on actual weight and dimension of the item(s).',
  purolatorDisclaimerMessage2:
    'A freight surcharge may be applicable on all items classified as DANGEROUS GOODS.',
  purolatorDisclaimerMessage3:
    'Orders placed on a Saturday or Sunday will be treated as Monday orders.',
  purolatorShippingEstimate: 'Purolator Shipping Estimate',
  qty: 'Qty',
  qtyAZ: 'Qty (Low-High)',
  qtyZA: 'Qty (High-Low)',
  qtyPriceBreak: 'Qty Price Break',
  qtyToReturn: 'QTY TO RETURN',
  quantity: 'Quantity',
  quantityPriceBreak: 'Quantity Price Break',
  quantitySelectedIsUnavailableAt: 'Quantity selected is unavailable at',
  quantityRequestedIsLessThanMinimum: 'Quantity requested is less than minimum',
  quantityRequestedHasChanged: 'Quantity requested has changed.',
  quoteDeletedSuccessfully: 'Quote is deleted successfully',
  quoteDetails: 'Quote Details',
  quoteId: 'Quote ID',
  quoteNotFound: 'Quote not found',
  quoteSummary: 'Quote Summary',
  quoteVehicleNotFound: "Quote's vehicle information not found",
  quotes: 'Quotes',
  quotesOlderThan: 'quotes older than',
  rate: 'Rate',
  released: 'Released',
  releasedBy: 'Released by',
  reName: 'Rename',
  reNameGroup: 'Rename Group',
  readLess: 'Read Less',
  readMore: 'Read More',
  reason: 'Reason',
  reasonForReturn: 'REASON FOR RETURN',
  recentOrders: 'Recent Orders',
  recentSearches: 'Recent Searches',
  recentVehicles: 'Recent Vehicles',
  recommended: 'Recommended',
  recommendedServices: 'Recommended Services',
  recommendedServicesBody:
    'Select a service interval type and mileage to see recommended services',
  removeAll: 'Remove All',
  removeAllCarts: 'Remove All Carts',
  removeCart: 'Remove cart',
  removeItem: 'Remove Item',
  removeItemFromReturnRequest: 'Remove Item from Return Request',
  removeYour: 'remove your',
  rename: 'Rename',
  renameCategory: 'Rename Category',
  renameGroup: 'Rename Group',
  renameList: 'Rename list',
  replacementFor: 'Replacement for',
  reportLostSale: 'Report Lost Sale',
  requestNewCode: 'Request a new code',
  requestPasswordReset: 'Request password reset',
  requestReturn: 'Request Return',
  requestReturnId: 'Request Return ID',
  requestSummary: 'Request Summary',
  resultsTableDensity: 'Results Table Density',
  resetYourPassword: 'Reset your password',
  resortItems: 'Resort Items',
  results: 'Results',
  resultsFilter: 'Results Filter',
  resultsLoaded: 'Results loaded',
  returnAmountText:
    'This amount will be credited back to your account once the return request is processed and authorized.',
  returnRequest: 'Return Request',
  returnRequestDetails: 'Return Request Details',
  returnSummary: 'Return Summary',
  returnToThePartsCatalogToKeepShopping:
    'Return to the parts catalog to keep shopping.',
  returnToVehicleHistory: 'Return to vehicle history',
  reviewItemsaddedtocart: 'Review Items Added to Cart',
  rma: 'RMA',
  rmaRequest: 'RMA Request',
  rmaConfirmation: 'RMA Confirmation',
  rollOverImageToZoom: 'Roll over image to zoom',
  rollOverImageToMagnify: 'Roll over image to magnify',
  saveAll: 'Save All',
  saveAsQuote: 'Save As Quote',
  saveChanges: 'Save Changes',
  savePreferences: 'Save Preferences',
  secondary: 'Secondary',
  screenName: 'Screen Name',
  screenNameRequired: 'Screen name required',
  search: 'Search',
  searchSettings: 'Search Settings',
  searchAllQuotesBy: 'Search all quotes (by PO, part number, notes, location)',
  searchAllOrders: 'Search all orders (by PO, invoice #, part number, vehicle)',
  searchAllInvoices: 'Search all invoices (by PO,part #,invoice number)',
  searchByTabOrder: '“Search by” Tab Order',
  searchByLicensePlate: 'Search by License Plate',
  searchByVin: 'Search by VIN',
  searchByYMME: 'Search by Year, Make, Model',
  searchDate: 'Search Date',
  searchForVehicles: 'Search for vehicles (custom name, license plate...)',
  searchManufacturers: 'Search Manufacturers',
  searchOnWeb: 'Search on Web',
  searchPartBrandName: 'Search Part #, Brand, or Part Name',
  searchParts: 'Search Parts',
  searchbyPartTypes: 'Search by Part Types',
  searchTermShouldBeMinimum3Characters:
    'Search term should be minimum 3 characters',
  searchWithinResults: 'Search within results',
  seeLess: 'See Less',
  seeMore: 'See More',
  select: 'Select',
  selectAConditionToChangeGraphic:
    'Select a condition to change the graphic displayed',
  selectAVehicle: 'Select a Vehicle',
  selectAll: 'Select All',
  selectAllItemsInThisList: 'Select all items in this list',
  selectAllParts: 'Select All Parts',
  selectAnotherLocation: 'Select Another Location',
  selectCartToAddItem: 'Select Cart To Add Item',
  selectCategory: 'Select Category',
  selectDescription: 'Select Description',
  selectFromManufacturer: `Select "From" Manufacturer`,
  selectFromManufacturerDescription: `Optimize my interchange search by first displaying all “from” manufacturers that correspond to the specified part number before showing my results.`,
  selectGfxAndFitmentConditions: 'Select GFX and Fitment Conditions',
  selectGroup: 'Select Group',
  selectManufactureLines:
    'Select the manufacturer lines that you would like to filter the results.',
  selectManufacturersUpto: ' You can select upto 10 manufacturers.',
  selectPartType: 'Select Part Type',
  selectPartTypes: 'Select Part Types',
  selectPartsToReturn: 'Select Parts to Return',
  selectVehicle: 'Select vehicle',
  selectVehicleEngine: 'Select Vehicle Engine',
  selectYourItems: 'Select your items',
  selectYourPreferredDefaultVehicleTypeWhenDoingAYMMESearch:
    'Select your preferred default vehicle type when doing a YMME search',
  selected: 'selected',
  selectedAcross: 'selected across',
  selectedLines: 'Selected Lines',
  seller: 'Seller',
  sellFromID: 'Sell From ID',
  sellFromName: 'Sell From Name',
  sellToID: 'Sell To ID',
  sellToName: 'Sell To Name',
  sendAMessageTo: 'Send a message to',
  sendAnother: 'Send Another',
  sendCode: 'Send Code',
  sendMessage: 'Send Message',
  sendUsaMessage: 'Send us a message',
  separateMultiplePartNumbers: 'Separate multiple part #s with spaces',
  separateMultiplePartNumbersWithSpaces:
    'separate multiple part numbers with spaces',
  serviceIntervals: 'Service Intervals',
  servicesIntervalType: 'Services Interval Type',
  setLaborRate: 'Set Labor Rate',
  severe: 'Severe',
  shipped: 'shipped',
  shipByCourier: 'Ship By Courier',
  shipFromAddress: 'Ship From Address',
  shipFromID: 'Ship From ID',
  shipFromName: 'Ship From Name',
  shipInstructions: 'Ship Instructions',
  shipTo: 'Ship To',
  shipToAddress: 'Ship To Address',
  shipToName: 'Ship To Name',
  shipment: 'Shipment',
  shipmentCost: 'Shipment Cost',
  shippedFrom: 'Shipped From',
  shipping: 'Shipping',
  shippingCostIsUpdated:
    'Your shipping cost has changed based on updated quantity',
  shippingId: 'Shipping ID',
  'Show All': 'Show All',
  showGraphic: 'Show Graphic',
  showResults: 'Show Results',
  showLess: 'Show Less',
  showMore: 'Show More',
  showing: 'Showing',
  showingResultsFor: 'Showing results for',
  skillLevel: 'Skill Level',
  skipAndSeeAllResults: 'Skip and see all results',
  sort: 'Sort',
  sortBy: 'Sort by',
  sortGroup: 'Sort Group',
  sortHasNotYetBeenApplied: 'Sort has not yet been applied',
  sortResult: 'Sort Results',
  source: 'Source',
  spanish: 'Spanish',
  specificationSearch: 'Search specifications',
  specificationUpdate: 'Specifications updated',
  specifications: 'Specifications',
  specify: 'Specify If Other',
  startNewCart: 'Start new cart',
  startNewOrder: 'Start New Order?',
  state: 'State',
  status: 'Status',
  statusUnavailable: 'Status Unavailable',
  stock: 'Stock',
  stockQty: 'Stock Qty',
  subOperation: 'Sub Operation',
  subject: 'Subject',
  submit: 'Submit',
  subModel: 'Submodel',
  successfullyCreatedCategory: 'Successfully created category',
  successfullyCreatedGroup: 'Successfully created group',
  successfullyCreatedList: 'Successfully created list',
  successfullyDeletedCategory: 'Successfully deleted category',
  successfullyDeletedGroup: 'Successfully deleted group',
  successfullyDeletedList: 'Successfully deleted list',
  successfullyListMoved: 'List successfully moved',
  successfullyUpdatedCategory: 'Successfully updated category',
  successfullyUpdatedGroup: 'Successfully updated group',
  successfullyUpdatedList: 'Successfully updated list',
  summary: 'Summary',
  switchOrg: 'Switch Org',
  temporarilyUnavailable: 'Temporarily Unavailable',
  theFollowingItemsWereLoaded:
    'The following items were loaded after you applied a custom sort. To apply your sort to the items below click resort items',
  theMinimumOrderQuantityIs: 'The minimum order quantity is %.',
  thenBy: 'Then by',
  thePageYourRequestedCouldNotBeFound:
    'The page you requested could not be found',
  thePartWasAddedToYourCart: 'The part was added to your cart',
  fewOfThePartsWereNotAddedToCart:
    'few of the parts were not added to the cart',
  NoPartWasNotAddedToTheCart: 'No part Was added to your Cart',
  theQuantityRequestedIsNotAvailable: 'The quantity requested is not available',
  editThisItemToAddItToYourCart: 'Edit this item to add it your cart',
  theQuoteWasSavedSuccessfully: 'The quote was saved successfully',
  theseItemsWereUnavailableFromOurDatabase:
    'These items were unavailable from our database',
  thesePartsMayNotFitYour: 'These parts may not fit your',
  theseTicketsWillNotBeSentOnTheTicketToTheWarehouse:
    'These fields will not be sent on the ticket to the warehouse.',
  theSelectedPartsWillAppearHear: 'The selected parts will appear here.',
  thisGroupIsEmpty: 'THIS GROUP IS EMPTY',
  thisCannotBeUndone: 'This cannot be undone',
  thisIncludesTheFollowingCarts: 'This includes the following carts',
  thisItemWasUnavailableFromOurDatabase:
    'This item was unavailable from our database',
  thisOrderHasQualifiedFor: 'This order has qualified for',
  thisQuoteCantBeFulfilled: 'This Quote can not be fulfilled',
  thisVinIsInvalidTryAgain:
    'This VIN is invalid. Please check the VIN and try again',
  invalidQuery: 'Invalid Query, search term should be atleast 3 characters.',
  thisWeek: 'This week',
  thisWillRemoveAllItemsFromTheCartAndCannotBeUndone:
    'This will remove all items from the cart and cannot be undone.',
  today: 'Today',
  toEnsureWeCanFindTheBestMatchPleaseEnterVehicleInformation:
    'To ensure we can find the best match please enter vehicle information.',
  tomorrow: 'Tomorrow',
  total: 'Total',
  totalApplications: 'Total Applications',
  totalCore: 'Total Core',
  totalCost: 'Total Cost',
  totalHours: 'Total Hours',
  totalLabor: 'Total Labor',
  totalList: 'Total List',
  totalQty: 'Total QTY',
  totals: 'Totals',
  totalTransport: 'Total Transport',
  tracking: 'Tracking',
  trackingHistory: 'Tracking History',
  trackingInformationIsNotYetAvailable:
    'Tracking information is not yet available for this shipment, please check back later',
  trackingShipment: 'Tracking Shipment',
  transfer: 'Transfer',
  transID: 'Trans ID',
  transport: 'Transport',
  tryANewSearchOrEditYourFitmentConditions:
    'Try a new search or edit your fitment conditions',
  tryANewSearchOrUseThePartsCatalog:
    'Try a new search or use the Parts Catalog',
  tryAdjustingYourFilters: 'Try adjusting your filters',
  tryAdjustingYourSearchOrLookupPartsInOurDatabase:
    'Try adjusting your search or lookup parts in our database',
  trySelectingDifferentGroups:
    'Try selecting different groups or looking up another vehicle',
  type: 'Type',
  typeANameForThisVehicle: 'Type a name for this vehicle',
  uid: 'UID',
  underWarranty: 'Under Warranty',
  unDone: 'This cannot be undone.',
  unableToFetchManufacturer: 'Unable to fetch manufacturer',
  unableToFetchPartTypes: 'Unable to fetch part types',
  unableToPlaceOrder: 'Unable to place order',
  unableToPlaceOrderError: `Unable to place Order: Part Inquiry/Ordering Error`,
  unableToPlaceOrderPleaseContactTheAdministrator:
    'Unable to place order. Please contact the administrator.',
  unavailableAt: 'Unavailable at %',
  unavailableAtAndProductCantBeFulfilled:
    'Unavailable at % and product cant be fulfilled',
  unavailableGroupGraphic: 'Unavailable Group Graphic',
  unavailablePartTypes: 'Unavailable Part Types',
  unavailableProductDetails: 'Unavailable Product Details',
  unknownAftermarketAvailability: 'Unknown Aftermarket Availability',
  unknownError: 'An unknown error occured',
  unknownErrorPurolatorEstimates:
    'An unknown error occured while fetching purolator estimates',
  unSelectAll: 'Unselect All',
  update: 'Update',
  updateCart: 'Update Cart',
  updateList: 'Update List',
  updateQuote: 'Update Quote',
  updateVehicle: 'Update Vehicle',
  updatingYourVehicleWillChangeSearch:
    'Updating your vehicle will change your current search results.  Do you want to update?',
  urgent: 'Urgent',
  useTemplateCsv: 'Use our template list (CSV)',
  usedPartInbox: 'Used Part Inbox',
  user: 'User',
  userDetails: 'User Details',
  userID: 'User ID',
  vehicle: 'Vehicle',
  vehicleClear: 'clear',
  vehicleDeleted: 'Vehicle deleted',
  vehicleDeletedSuccessfully: 'Vehicle deleted successfully',
  vehicleFitment: 'Vehicle Fitment',
  vehicleFitmentUnavailable: 'Vehicle Fitment is unavailable for this product.',
  vehicleHistory: 'Vehicle History',
  vehicleIncomplete: 'Vehicle Incomplete',
  vehicleInformationWasIncomplete:
    'Vehicle information was incomplete. Please add remaining vehicle data',
  vehicleInfoRetrievalError:
    'An error occurred while trying to retrieve the vehicle information.',
  vehicleLicensePlate: 'License Plate',
  vehicleNotFound: 'Vehicle Not Found',
  vehicleQuickFind: 'Vehicle Quick Find',
  vehicleRequired: 'Vehicle Required',
  vehicleRequiredForSearch: 'Please select a vehicle to continue.',
  vehicleSearch: 'Vehicle Search',
  vehicleSpecification: 'Specification',
  vehicleSuccessfullyDeleted: 'Vehicle successfully deleted',
  vehicleType: 'Vehicle Type',
  vehicleTypeDropdown: 'Vehicle type dropdown',
  vehicleVIN: 'VIN',
  vehicleYMME: 'Year/Make/Model/Engine',
  vehicles: 'Vehicles',
  vehicleName: 'Vehicle Name',
  verifyingQuantityAvailable: 'Verifying quantity available...',
  verifyingTheItemsInOurDatabase: 'Verifying items in our database...',
  verifyingTheLocationForItems: 'Verifying location for items...',
  viewCart: 'View Cart',
  viewDetails: 'View Details',
  viewLists: 'View Lists',
  viewMore: 'View More',
  viewOnly: 'View Only',
  viewOnlyLocationMessage:
    'View Only Location. Please select your local warehouse or store to order.',
  viewOrPrint: 'View or Print receipt',
  viewOrPrintQuote: 'View or Print quote',
  viewOrderDetails: 'View Order Details',
  viewPartAvailabilityInListBelow: 'View part availability in list below',
  viewPreviousSearches: 'View previous searches',
  viewQuoteDetails: 'View quotes details',
  viewQuotes: 'View Quotes',
  viewVinDetails: 'View Vin Details',
  vin: 'VIN',
  vinScan: 'VIN Scan',
  vinCode: 'Vin Code',
  vinDetails: 'VIN Details',
  vinMustContain17Characters: 'VIN must contain 17 characters',
  vinPattern: 'VIN Pattern',
  visitTheWebsiteForOrderDetails: 'Visit The Website for order Details',
  warning: 'Warning',
  warrantyHours: 'Warranty Hours',
  warrantyInfo: 'Warranty Info',
  wasAddedToList: 'was added to list',
  wasSavedAsQuote: '% was saved as quote',
  copyToAllCarts: 'Copy to all carts',
  wasSuccessfullyUpdate: '(%) was successfully updated',
  weAreValidatingAvailability: "We're Validating Availability.",
  weUpdatedYourLocationTo: 'We updated your location to',
  weight: 'Weight',
  wereSorryAGraphicFor: "We're sorry, a graphic for",
  wereSorryTheProductDetailsFor: "We're sorry, the product details for",
  whyAreYouReturningThisPart: 'Why are you returning this part?',
  wildCardSearchNotAllowedText: 'Wildcard search is not allowed',
  willBeOrderFromANonPrimaryLocation:
    'will be ordered from %, a non-primary location',
  wrongPartFromCatalog: 'Wrong Part From Catalog',
  wtyHours: 'WTY Hours',
  year: 'Year',
  years: 'Years',
  yesEmpty: 'Yes, Empty',
  yesIUnderstand: 'Yes, I understand',
  yesterday: 'Yesterday',
  youHaveReachedTheMaximumNumberOfEntries:
    'You have reached the maximum number of entries',
  youHaveEntryToDate: 'You have % entry(s) to date.',
  yourCartIsEmpty: 'Your Cart is Empty',
  yourCartWasSuccessfullyEmptied: 'Your cart was successfully emptied.',
  yourCost: 'Your Cost',
  yourFiltersReturnedNoResults: 'Your filters returned no results',
  yourFirstItemWillBeYourDefault: 'Your first item will be your default',
  yourSearchReturnedNoResults: 'Your search returned no results',
  zeroAvailableAtLocation: '0 available at %',
  'Not Available': 'Not Available',
  'Price Too High': 'Price Too High',
  'Prefer Different Brand': 'Prefer Different Brand',
  Other: 'other',
  otherInfo: 'Other',
  CAR: 'CAR',
  TRUCK: 'TRUCK',
  'MEDIUM DUTY': 'MEDIUM DUTY',
}
